import { Button, Col, Form, Row } from 'react-bootstrap';

import React, { useState } from 'react';
import { Link } from 'gatsby';
import icon from 'images/footerIcon.svg';
import logo from 'images/footerLogo.svg';
import socialMedia from 'images/socialMedia.svg';
import axios from 'axios';

const Footer = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [subEmail, setSubEmail] = useState('');

    const resetFormData = () => {
        setEmail('');
        setMessage('');
        setName('');
    }

    const resetSubEmail = () => {
        setSubEmail('');
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = {
            name: name,
            email: email,
            message: message
        };
        submitData(data);
        resetFormData();
    };

    const handleNewsLetter = (event) => {
        event.preventDefault();
        const data = {
            email: subEmail,
        }
        submitNewLetter(data);
        resetSubEmail();
    };

    const submitNewLetter = async (data) => {
        const options = {
            method: "POST",
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: "https://script.google.com/macros/s/AKfycbwu-r4XdO1qoQ55E3bQ-Z0Mq9PQpgrUhrjOGLjsF3NUnGPC2dLUwUHDx0LycsYivdZnKg/exec?sheetName=newsletter",
            data: JSON.stringify(data)
        }
        //eslint-disable-next-line
        const response = await axios(options);
    }

    const submitData = async (data) => {
        const options = {
            method: "POST",
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: "https://script.google.com/macros/s/AKfycbwu-r4XdO1qoQ55E3bQ-Z0Mq9PQpgrUhrjOGLjsF3NUnGPC2dLUwUHDx0LycsYivdZnKg/exec?sheetName=footer",
            data: JSON.stringify(data)
        }
        //eslint-disable-next-line
        const response = await axios(options);
    }


    return (
        <Row style={{ background: '#5958C7', color: 'white', paddingTop: '20px', display: 'flex', justifyContent: 'space-between', paddingBottom: '50px', fontFamily: 'Inria Sans' }}>
            <Col xs={12} md={{ span: 2 }} style={{ marginBottom: '50px' }}>
                <Col style={{ diaplay: 'flex' }}>
                    <img src={icon} alt="logo" />
                    <img src={logo} alt="icon" />
                </Col>
                <Col style={{ marginTop: '30px', marginLeft: '10px' }}>
                    <img src={socialMedia} alt="social media" />
                </Col>
            </Col>
            <Col xs={{ span: 11 }} md={{ span: 2 }} style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '30px' }}>
                <p style={{ fontSize: '24px' }}>Contacts</p>
                <p>General Inquires - support@unomok.com</p>
                <p>Sales - 91 - 880707 - 3737</p>
                <p>Support - 1 - 812 - 00281354</p>
                <p>1014 Center Rd Suite 408-B Wilmington DE 19805 USA</p>
                <p>219 Jayanagar Bangalore India</p>
            </Col>
            <Col xs={{ span: 5 }} md={{ span: 2 }} style={{ fontSize: '18px', fontWeight: 'bold' }}>
                <p style={{ fontSize: '24px' }}>Company</p>
                <p>
                    <Link style={{ color: '#fff' }} to='/features'>Products</Link></p>
                <p>
                    <Link style={{ color: '#fff' }} to='/solutions'>Solutions</Link>
                </p>
                <p>
                    <Link style={{ color: '#fff' }} to='/pricing'>Pricing</Link>
                </p>
                <p>
                    <Link style={{ color: '#fff' }} to='/about-us'>About Us</Link>
                </p>
            </Col>
            <Col xs={{ span: 5 }} md={{ span: 2 }} style={{ fontSize: '18px', fontWeight: 'bold' }}>
                <p style={{ fontSize: '24px' }}>Resources</p>
                <p><a style={{ color: '#fff' }} href='https://blog.unomok.com'>Blogs</a></p>
                <p><a style={{ color: '#fff' }} href='https://blog.unomok.com'>Case Studies</a></p>
                <p><a style={{ color: '#fff' }} href='https://blog.unomok.com'>Testimonials </a></p>
                <p><a style={{ color: '#fff' }} href='https://blog.unomok.com'>Videos</a></p>
                <p><a style={{ color: '#fff' }} href='https://blog.unomok.com'>DIY</a></p>
                <p><a style={{ color: '#fff' }} href='https://blog.unomok.com'>Press</a></p>
                <p>Privacy Policy</p>
                <p>Terms of Use</p>
            </Col>
            <Col className="d-none d-lg-block" xs={6} md={{ span: 3 }} style={{ fontSize: '18px', fontWeight: 'bold', paddingRight: '20px' }}>
                <p style={{ fontSize: '24px' }}>Request a Demo</p>
                <p>Schedule a demo with our product consultant.</p>
                <Form style={{ width: '90%' }} onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Control style={{ borderRadius: '10px' }} value={name} onChange={(e) => setName(e.target.value)} required placeholder="Name" />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Control style={{ borderRadius: '10px' }} required value={email} onChange={(e) => setEmail(e.target.email)} type="email" placeholder="Email" />
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Control style={{ borderRadius: '10px' }} onChange={(e) => setMessage(e.target.value)} value={message} as="textarea" required placeholder="Message" rows={2} />
                    </Form.Group>
                    <Button className="footerButton" variant="primary" type="submit">
                        Submit
                    </Button>
                </Form>
                <div style={{ marginTop: '60px' }}>
                    <p>Stay Connected</p>
                    <p>Subscribe to our news letter.</p>
                    <Form style={{ width: '90%' }} onSubmit={handleNewsLetter}>
                        <Form.Control style={{ borderRadius: '10px' }} value={subEmail} onChange={(e) => setSubEmail(e.target.value)} required type="email" placeholder="Email" />
                        <Button className="footerButton" variant="primary" type="submit">
                            Submit
                        </Button>
                    </Form>
                </div>
            </Col>
        </Row >
    )
};

export default Footer;